import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import logo from './media/full.gif';
import es from './media/es.png'
import tg from './media/tg.png'
import tw from './media/tw.png'
import db from './media/db.png'
import dx from './media/dx.png'
import td from './media/td.gif'
import gif from './media/gif.gif'
import stonks from './media/stonks.gif'
import snort from './media/snort.jpg'
import money from './media/money.gif'
import buy from './media/buy.gif'
import eth from './media/eth.png'
import ab from './media/about.png'
import pump from './media/pumpfun.png'
import audioFile from './media/audio.mp3';
import './App.css';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // Use useEffect to show the modal when the component mounts
  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce3'>
        <div className="lol-wrap x">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <div className='bounce4'>
        <div className="le-wrap w">
          <img className="le z" src={stonks}></img>
        </div>
      </div>
      <div className='bounce5'>
        <div className="lol-wrap x">
          <img className="le y" src={money}></img>
        </div>
      </div>
      <div className='bounce6'>
        <div className="lol-wrap y">
          <img className="le x" src={snort}></img>
        </div>
      </div>
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce3'>
        <div className="lol-wrap x">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <div className='bounce4'>
        <div className="le-wrap w">
          <img className="le z" src={stonks}></img>
        </div>
      </div>
      <div className='bounce5'>
        <div className="lol-wrap x">
          <img className="le y" src={money}></img>
        </div>
      </div>
      <div className='bounce6'>
        <div className="lol-wrap y">
          <img className="le x" src={snort}></img>
        </div>
      </div>
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce3'>
        <div className="lol-wrap x">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <div className='bounce4'>
        <div className="le-wrap w">
          <img className="le z" src={stonks}></img>
        </div>
      </div>
      <div className='bounce5'>
        <div className="lol-wrap x">
          <img className="le y" src={money}></img>
        </div>
      </div>
      <div className='bounce6'>
        <div className="lol-wrap x">
          <img className="le x" src={snort}></img>
        </div>
      </div>
      <div className='bounce7'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce8'>
        <div className="lol-wrap y">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce9'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce10'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce11'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce12'>
        <div className="lol-wrap y">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <div className='bounce13'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce14'>
        <div className="lol-wrap x">
          <img className="el x" src={buy}></img>
        </div>
      </div>
      <div className='bounce15'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce16'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce17'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce18'>
        <div className="lol-wrap y">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <div className='bounce19'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce20'>
        <div className="lol-wrap x">
          <img className="el x" src={buy}></img>
        </div>
      </div>
      <div className='bounce21'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce22'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce23'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce24'>
        <div className="lol-wrap y">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <div className='bounce25'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce26'>
        <div className="lol-wrap x">
          <img className="el x" src={buy}></img>
        </div>
      </div>
      <div className='bounce27'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce28'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce29'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce30'>
        <div className="lol-wrap z">
          <img className="le z" src={snort}></img>
        </div>
      </div>
      <div className='bounce31'>
        <div className="el-wrap z">
          <img className="el z" src={gif}></img>
        </div>
      </div>
      <div className='bounce32'>
        <div className="lol-wrap z">
          <img className="el z" src={buy}></img>
        </div>
      </div>
      <div className='bounce33'>
        <div className="le-wrap x">
          <img className="le x" src={eth}></img>
        </div>
      </div>
      <div className='bounce34'>
        <div className="le-wrap z">
          <img className="le x" src={stonks}></img>
        </div>
      </div>
      <div className='bounce35'>
        <div className="lol-wrap y">
          <img className="le z" src={money}></img>
        </div>
      </div>
      <div className='bounce36'>
        <div className="lol-wrap z">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <body>
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <h1 className='top front'>$GPT</h1>
        <div className='container front'>
          <a className='card'>
            <img src={logo} className="App-logo" alt="logo" width='500vw'/>
          </a>
        </div>
        <h1 className='top front'>GIGA PUMP TOKEN</h1>
        <header className="App-header">
        <div className='container front'>
          <div className='item'>
            <a href='https://t.me/GPTgigapumptoken'><img src={tg} width='120px'/></a>
          </div>
          <div className='item'>
            <a href='https://twitter.com/GPTonsolana'><img src={tw} width='120px'/></a>
          </div>
          <div className='item'>
            <a href=''><img src={dx} width='105px'/></a>
          </div>
          <div className='item'>
            <a href='https://pump.fun/61W24eVxnRYfHmMr8gkDCAG5W8h3SiNf6YJ4ugvuh9TH'><img src={pump} width='105px'/></a>
          </div>
        </div>
      </header>
        <h1 className='bottom front'>GET READY FOR THE GREEN DILDO CANDLE</h1>
        <div className='front about'>
            <a href='' className='fairy'><img src={ab} width='300px'/></a>
        </div>
        <h1 className='bottom front'>ITS ON ITS WAY</h1>
        <img src={td} width='500vw' className='front'/>
        <h1 className='bottom front'> ABOUT <br/><br/> $GPT was created to be the best token ever to be deployed <br/><br/> So great your grandma is gona wish she aped <br/><br/> We're going to make tokens great again <br/><br/> Get Ready to Flex Your Finances with $GPT <br/> <br/> Brace yourself for gains that'll make your biceps jealous <br/> <br/> FOMO that hits harder than a missed leg day <br/> </h1>
        <img src={db} width='500vw' className='front'/>
        <h1 className='top front'>$GPT (moon)</h1>
        <p className='title front'>0/0 TAX, CONTRACT RENOUNCED, LP LOCKED. <br/>LIQUITY LOCKED<br/> true community token</p>
        <p className='fairy'>Let's get these gainzzzzzz</p>
      </body>
    </div>
  );
}

export default App;
